import {
  Assignment,
  Assignments,
} from "classes/models/assignments/assignment.model";
import {
  Classroom,
  Classrooms,
} from "classes/models/classrooms/classroom.model";
import { ClassroomsService } from "classes/models/classrooms/classrooms.service";
import { Students } from "classes/models/students/student.model";
import { catchError, Subscription } from "rxjs";
import { SubmittedDocuments } from "types/SubmittedDocument";
import { ClassroomTemplates } from "~/classes/models/classroom-templates/classroom-template.model";
import { ClassroomTemplatesService } from "~/classes/models/classroom-templates/classroom-templates.service";
import { AssignmentType } from "~/types/enums/AssignmentType.enum";

export const useUserClassroomsData = (userId: string) =>
  defineStore(`/users/${userId}/classrooms/data`, () => {
    const classrooms = ref<Classrooms>([]);
    const classroomTemplates = ref<ClassroomTemplates>([]);

    const userClassroomsSubscription = ref<Subscription | undefined>();
    const userClassroomTemplatesSubscription = ref<Subscription | undefined>();

    const isInitialized = ref(false);

    const reset = () => {
      classrooms.value = [];
      classroomTemplates.value = [];
      isInitialized.value = false;
      userClassroomsSubscription.value?.unsubscribe();
      userClassroomTemplatesSubscription.value?.unsubscribe();
    };

    const appUserDataStore = useAppUserData();
    const { linkedOrganizationIds, adminOrganizationIds } =
      storeToRefs(appUserDataStore);

    const initialize = async () => {
      if (isInitialized.value) return;

      // classrooms.value = await ClassroomsService.getUserClassrooms(userId);

      userClassroomsSubscription.value?.unsubscribe();
      userClassroomsSubscription.value = ClassroomsService.streamUserClassrooms(
        userId
      )
        .pipe(
          catchError((error: any) => {
            console.error("Error streaming user classrooms", error);
            return [];
          })
        )
        .subscribe((data) => {
          classrooms.value = data;

          for (const classroom of classrooms.value) {
            if (classroom.isArchived != true) {
              initializeClassroom(classroom);
            }
          }
        });

      userClassroomTemplatesSubscription.value?.unsubscribe();
      userClassroomTemplatesSubscription.value =
        ClassroomTemplatesService.streamUserClassroomTemplates({
          userId,
          organizationIds:
            linkedOrganizationIds.value.length > 0 ||
            adminOrganizationIds.value.length > 0
              ? [...linkedOrganizationIds.value, ...adminOrganizationIds.value]
              : undefined,
        })
          .pipe(
            catchError((error: any) => {
              console.error("Error streaming user classroom templates", error);
              return [];
            })
          )
          .subscribe((data) => {
            classroomTemplates.value = data.filter(
              (classroomTemplate) => classroomTemplate.isArchived != true
            );
          });

      isInitialized.value = true;
    };

    const initializeClassroom = (classroom: Classroom) => {
      if (classroom.id === undefined) return;

      const classroomDataStore = useClassroomDataStore(userId, classroom.id);
      classroomDataStore.initialize(classroom);
    };

    const initializeAssignment = (assignment: Assignment) => {
      if (assignment.id === undefined) return;

      const assignmentDataStore = useAssignmentDataStore(
        assignment.classroomId,
        assignment.id
      );
      assignmentDataStore.initialize();
    };

    const numUngradedDocuments = computed(() => {
      let numDocuments = 0;

      for (const classroom of classrooms.value) {
        if (!classroom.id) continue;

        const classroomDataStore = useClassroomDataStore(userId, classroom.id);
        const { numUngradedDocuments } = storeToRefs(classroomDataStore);
        numDocuments += numUngradedDocuments.value;
      }

      return numDocuments;
    });

    const numGradedDocuments = computed(() => {
      let numDocuments = 0;

      for (const classroom of classrooms.value) {
        if (!classroom.id) continue;

        const classroomDataStore = useClassroomDataStore(userId, classroom.id);
        const { numGradedDocuments } = storeToRefs(classroomDataStore);
        numDocuments += numGradedDocuments.value;
      }

      return numDocuments;
    });

    const numStudents = computed(() => {
      let allStudentIds = [] as string[];

      for (const classroom of classrooms.value) {
        if (!classroom.id) continue;

        const classroomDataStore = useClassroomDataStore(userId, classroom.id);
        const { students } = storeToRefs(classroomDataStore);
        const studentIds = students.value.map((student) => student.id!);
        allStudentIds = [...allStudentIds, ...studentIds];
      }

      return [...new Set(allStudentIds)].length;
    });

    const activeAssignments = computed(() => {
      const allAssignments = [] as Assignments;

      for (const classroom of classrooms.value) {
        const classroomDataStore = useClassroomDataStore(userId, classroom.id!);
        const { assignments } = storeToRefs(classroomDataStore);

        // ok here what I need to do is loop through each assignment and determine if it's active
        // It's active if there is at least one submitted document and hte due date is within 7 days

        const activeAssignments = assignments.value.filter((assignment) => {
          const assignmentDataStore = useAssignmentDataStore(
            assignment.classroomId,
            assignment.id!
          );
          const { numGradedDocuments, ungradedDocuments } =
            storeToRefs(assignmentDataStore);

          if (
            numGradedDocuments.value == 0 &&
            ungradedDocuments.value.length == 0
          )
            return false;

          if (assignment.dueTimestamp == undefined) return true;

          // Is today wuthin 7 days of the due date?
          const dueDate = new Date(assignment.dueTimestamp);
          const today = new Date();
          const sevenDaysAgo = new Date();
          sevenDaysAgo.setDate(today.getDate() - 7);

          if (dueDate > sevenDaysAgo) return true;

          return false;
        });

        allAssignments.push(...activeAssignments);
      }

      allAssignments.sort((a, b) => {
        // sort by dueTimestamp ascending
        if (a.dueTimestamp == undefined) return 1;
        if (b.dueTimestamp == undefined) return -1;
        return a.dueTimestamp - b.dueTimestamp;
      });

      return allAssignments;
    });

    const numActiveAssignmentsStudents = computed(() => {
      let numStudents = 0;

      for (const assignment of activeAssignments.value) {
        if (!assignment.id) continue;

        const classroomDataStore = useClassroomDataStore(
          userId,
          assignment.classroomId
        );
        const { students } = storeToRefs(classroomDataStore);

        numStudents += students.value.length;
      }

      return numStudents;
    });

    const numActiveAssignmentsSubmittedDocuments = computed(() => {
      let numDocuments = 0;

      for (const assignment of activeAssignments.value) {
        if (!assignment.id) continue;

        const assignmentDataStore = useAssignmentDataStore(
          assignment.classroomId,
          assignment.id
        );
        const { numGradedDocuments, ungradedDocuments } =
          storeToRefs(assignmentDataStore);

        numDocuments +=
          ungradedDocuments.value.length + numGradedDocuments.value;
      }

      return numDocuments;
    });

    const numActiveAssignmentsGradedDocuments = computed(() => {
      let numDocuments = 0;

      for (const assignment of activeAssignments.value) {
        if (!assignment.id) continue;

        const assignmentDataStore = useAssignmentDataStore(
          assignment.classroomId,
          assignment.id
        );
        const { numGradedDocuments } = storeToRefs(assignmentDataStore);

        numDocuments += numGradedDocuments.value;
      }

      return numDocuments;
    });

    const hasAssignmentsWithoutChecklists = computed(() => {
      return assignmentsWithoutChecklist.value.length > 0;
    });

    const assignmentsWithoutChecklist = computed(() => {
      const allAssignments = [] as Assignments;

      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      for (const classroom of classrooms.value) {
        const classroomDataStore = useClassroomDataStore(userId, classroom.id!);
        const { assignments } = storeToRefs(classroomDataStore);

        const assignmentsWithoutChecklist = assignments.value.filter(
          (assignment) =>
            assignment.type == AssignmentType.iewComposition &&
            assignment.checklistId == undefined &&
            (assignment.dueTimestamp ?? 0) > sevenDaysAgo.getTime()
        );
        allAssignments.push(...assignmentsWithoutChecklist);
      }

      allAssignments.sort((a, b) => {
        // sort by dueTimestamp ascending
        if (a.dueTimestamp == undefined) return 1;
        if (b.dueTimestamp == undefined) return -1;
        return a.dueTimestamp - b.dueTimestamp;
      });

      return allAssignments;
    });

    const assignmentsWithoutChecklistGroupedByClassroom = computed(() => {
      const assignments = assignmentsWithoutChecklist.value.filter(
        (assignment) => assignment.checklistId == undefined
      );

      const groupedAssignments: {
        [key: string]: Assignment[];
      } = {};

      for (const assignment of assignments) {
        if (!groupedAssignments[assignment.classroomName]) {
          groupedAssignments[assignment.classroomName] = [];
        }

        groupedAssignments[assignment.classroomName].push(assignment);
      }

      return groupedAssignments;
    });

    /// all data

    const allAssignments = computed(() => {
      const assignmentsList = [] as Assignments;

      for (const classroom of classrooms.value) {
        const classroomDataStore = useClassroomDataStore(userId, classroom.id!);
        const { assignments } = storeToRefs(classroomDataStore);

        // assignmentsList.push(...assignments.value);
      }

      return assignmentsList;
    });

    const allStudents = computed(() => {
      const studentsList = [] as Students;

      for (const classroom of classrooms.value) {
        const classroomDataStore = useClassroomDataStore(userId, classroom.id!);
        const { students } = storeToRefs(classroomDataStore);

        studentsList.push(...students.value);
      }

      return studentsList;
    });

    const allSubmittedDocuments = () => {
      const allSubmittedDocuments = [] as SubmittedDocuments;

      for (const assignment of allAssignments.value) {
        const assignmentDataStore = useAssignmentDataStore(
          assignment.classroomId,
          assignment.id!
        );
        const { gradedDocuments, ungradedDocuments } =
          storeToRefs(assignmentDataStore);

        allSubmittedDocuments.push(...gradedDocuments.value);
        allSubmittedDocuments.push(...ungradedDocuments.value);
      }

      return [...allSubmittedDocuments];
    };

    // Get a particular entity
    const classroomFromId = computed(() => {
      return (classroomId: string) => {
        return classrooms.value.find(
          (classroom) => classroom.id === classroomId
        );
      };
    });

    const activeClassrooms = computed((): Classrooms => {
      return useActiveClassrooms(classrooms.value);
    });

    const visibleClassrooms = computed(() => {
      return activeClassrooms.value.filter((classroom) => {
        return (
          classroom.shouldHide == undefined || classroom.shouldHide == false
        );
      });
    });

    const archivedClassrooms = computed(() => {
      return classrooms.value.filter((classroom) => {
        return classroom.isArchived == true;
      });
    });

    return {
      classrooms,
      classroomTemplates,
      activeClassrooms,
      visibleClassrooms,
      archivedClassrooms,
      activeAssignments,
      assignmentsWithoutChecklist,
      isInitialized,
      initialize,
      numUngradedDocuments,
      numGradedDocuments,
      numStudents,
      numActiveAssignmentsGradedDocuments,
      numActiveAssignmentsStudents,
      numActiveAssignmentsSubmittedDocuments,
      hasAssignmentsWithoutChecklists,
      assignmentsWithoutChecklistGroupedByClassroom,

      allAssignments,
      allStudents,
      allSubmittedDocuments,

      classroomFromId,

      reset,
    };
  });
